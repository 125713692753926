import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Input, Label, Form, FormGroup } from "reactstrap";

import CustomTextButton from "components/Custom/Buttons/TextButton";
// import { convertToLamports } from "utils";
import { sellNfts } from "store/actions";

const SellingDetails = ({ nfts, dispatch }) => {
    return (
        <div>
            <Formik
                initialValues={{
                    amount: 0,
                }}
                validationSchema={Yup.object().shape({
                    amount: Yup.number()
                        .moreThan(0)
                        // .min(500, "Must be greater than 500")
                        .required("Selling Price is required"),
                })}
                onSubmit={(value) => {
                    value.data = nfts;
                    // value.amount = convertToLamports(value.amount);
                    dispatch(sellNfts(value));
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <div style={{ padding: "1em" }}>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label>Selling Price</Label>
                                <Input
                                    key="amount"
                                    name="amount"
                                    type="number"
                                    autoComplete="off"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.amount}
                                    min={1}
                                />
                                {Boolean(touched.amount && errors.amount) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.amount}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup>
                            <div>
                                <CustomTextButton
                                    title="Sell NFTs"
                                    style={{ width: "100%" }}
                                    onClick={handleSubmit}
                                    disabled={values.amount === 0}
                                    type="submit"
                                />
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default SellingDetails;
