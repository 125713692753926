import React from "react";

function Navbar() {
  return (
    <>
      {/* ***************************** Head Starts Here **********************************/}
      <div className="head-cover" style={{backgroundColor:"rgb(26,27,40)",color:"white"}}>
        <header id="menu-jk" className="container-fluid">
          <div>
            <div className="row head-ro">
              <div className="col-md-3 logo">
                <a
                  className="d-md-none small-menu"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <i className="fas d-lg-none  fa-bars" />
                </a>
                <a href="/">
                <h3 style={{color:"#3dc3ff",padding:"10px",fontFamily:"'El Messiri', sans-serif"}}>{process.env.REACT_APP_PROJECT_NAME}</h3>
                </a>
              </div>
              <div id="collapseExample" className="col-md-9  nav">
                <ul>
                  {/* <li>
                    <a href="index.html">home</a>
                  </li>
                  <li>
                    <a href="#features">benefits</a>
                  </li>
                  <li>
                    <a href="/startups">startups</a>
                  </li> */}
                  {/* <li className="btnll">
                    <a href="https://nft.funq.club/auth/listings" target="_self" rel="noopener noreferrer">
                    <button className="btn btn-sm btn-primary" style={{backgroundColor:"#3dc3ff", color:"black",border:0}}>
                      View marketplace
                    </button>
                    </a>
                  </li> */}
                  <li className="btnll">
                    <a href="/auth/login">
                    <button className="btn btn-sm btn-primary" style={{backgroundColor:"#3dc3ff", color:"black",border:0}}>
                     <strong> Login </strong>
                    </button>
                    </a>
                  </li>
                  {/* <li className="btnll">
                    <a href="/auth/signup">
                    <button className="btn btn-sm btn-primary" style={{backgroundColor:"#3dc3ff", color:"black",border:0}}>
                      Signup
                    </button>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}

export default Navbar;
