/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import CreateNewNft from "./CreateNewNft";
import TypeSelection from "./TypeSelection";

import { authorizedPost, toggleLoading } from "store/actions";
import { FETCH_ALL_NFT_TYPES } from "graphql";

export default ({ handleModal, handleInfoModal }) => {
    const dispatch = useDispatch();
    const [selectedType, setSelectedType] = useState(null);
    const [active, setActive] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [typesData, setTypesData] = useState([]);

    useEffect(() => {
        fetchMoreData();
    }, []);

    const fetchMoreData = async () => {
        dispatch(toggleLoading(true));
        let response = await authorizedPost(FETCH_ALL_NFT_TYPES, { pageNumber: pageNumber });
        if (response.status) {
            const {
                list_all_nft_types: { data },
            } = response.data;
            if (!data.length || data.length === 1) {
                if (data.length === 1) {
                    setSelectedType(data[0]);
                }
                dispatch(toggleLoading(false));
                return handlePage(2);
            }
            setTypesData(data ?? []);
        }
        dispatch(toggleLoading(false));
    };

    const handleType = (e) => {
        let filter = typesData.filter((j) => j._id === e.target.value);
        setSelectedType(filter[0]);
    };

    const handlePage = (value) => {
        setActive(value);
    };

    return (
        <>
            {active === 1 ? (
                <TypeSelection selected={selectedType} types={typesData} handleType={handleType} handleView={handlePage} />
            ) : (
                <CreateNewNft handleInfoModal={handleInfoModal} handleModal={handleModal} type={selectedType} />
            )}
        </>
    );
};
