/*eslint-disable*/
import React from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup, FormLabel, Grid } from "@material-ui/core";
import CustomTextButton from "components/Custom/Buttons/TextButton";

export default ({ selected, types, handleType, handleView }) => {
    return (
        <>
            <FormControl>
                <FormLabel style={{ color: "black" }}>Select your contract type</FormLabel>
                <RadioGroup onChange={(e) => handleType(e)}>
                    <Grid container justifyContent="flex-start">
                        {types.map((e, i) => {
                            return (
                                <Grid item key={i}>
                                    <FormControlLabel
                                        value={e?._id}
                                        control={<Radio />}
                                        label={e?.name.replaceAll("_", " ")}
                                        style={{ textTransform: "capitalize" }}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </RadioGroup>
            </FormControl>
            <Grid container justifyContent="center">
                <Grid item>
                    <CustomTextButton title="Continue" disabled={!selected?._id} onClick={() => handleView(2)} />
                </Grid>
            </Grid>
        </>
    );
};
