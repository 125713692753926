/*eslint-disable*/
import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Card } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { listNftDetails, resetNftDetailsData } from "store/actions";
import "./publicPage.css";
import CustomTextButton from "components/Custom/Buttons/TextButton";
import { getSolExplorerUrl } from "helpers";

function PublicNftDetails() {
    const dispatch = useDispatch();
    const { Id } = useParams();

    const { nftDetails } = useSelector(({ nfts: { details } }) => ({
        nftDetails: details,
    }));

    useEffect(() => {
        dispatch(resetNftDetailsData());
        dispatch(listNftDetails({ id: Id, isPublic: true }));
    }, []);

    return (
        <>
            <div className="namebar">
                <a href="/">
                    <h3>Thetokenlist</h3>
                </a>
            </div>

            <div className="page-nav no-margin" style={{ backgroundColor: "rgb(38,39,52)" }}>
                <div className="container">
                    <div className="row">
                        <h2 style={{ color: "white", fontFamily: "El Messiri" }}>Contract Details</h2>
                        <ul>
                            <li>
                                <a href="/" style={{ color: "white" }}>
                                    <i className="fas fa-home" style={{ color: "white" }} /> home
                                </a>
                            </li>
                            <li style={{ color: "white" }}>
                                <i className="fas fa-angle-double-right" style={{ color: "white" }} />
                                details
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <Grid container justifyContent="center" style={{ backgroundColor: "rgb(38,39,52)", color: "white" }}>
                <Grid item lg={6} md={7} sm={8} xs={10}>
                    <div className="explore">
                        {Object.keys(nftDetails).length ? (
                            <>
                                <div className="pic3">
                                    <img src={nftDetails?.art_url} alt="" />
                                </div>
                                <Card style={{ backgroundColor: "#1A1B28", boxShadow: "0px 0px" }}>
                                    <div className="description3">
                                        <h3 style={{ textAlign: "center" }}>{nftDetails?.name ?? "-"}</h3>

                                        <h4 style={{ textAlign: "center", fontSize: 15 }}>
                                            {nftDetails?.description ?? ""}
                                        </h4>

                                        <div className="row cont-row">
                                            <div className="col-sm-4">
                                                <label>NFT ID : </label>
                                            </div>
                                            <div className="col-sm-8 chip" style={{ fontSize: "14px" }}>
                                                {nftDetails?.nft_id ?? "-"}
                                            </div>
                                        </div>

                                        <div className="row cont-row">
                                            <div className="col-sm-4">
                                                <label>Owner : </label>
                                            </div>
                                            <div className="col-sm-8 chip" style={{ textTransform: "capitalize" }}>
                                                {nftDetails?.owner_id?.name ?? "-"}
                                            </div>
                                        </div>

                                        <div className="row cont-row">
                                            <div className="col-sm-4">
                                                <label>Status : </label>
                                            </div>
                                            <div className="col-sm-8 chip" style={{ textTransform: "capitalize" }}>
                                                {nftDetails?.status ?? "-"}
                                            </div>
                                        </div>
                                        {nftDetails?.type_id
                                            ? nftDetails?.type_id?.fields
                                                  .filter((e) => e?.name !== "name")
                                                  .map((e, i) => {
                                                      if (nftDetails[e?.name] && nftDetails[e?.name] !== "") {
                                                          return (
                                                              <div key={i} className="row cont-row">
                                                                  <div className="col-sm-4">
                                                                      <label style={{ textTransform: "capitalize" }}>
                                                                          {e?.name.replaceAll("_", " ")} :{" "}
                                                                      </label>
                                                                  </div>
                                                                  <div
                                                                      className="col-sm-8 chip"
                                                                      style={{ textTransform: "capitalize" }}
                                                                  >
                                                                      {nftDetails[e?.name]}
                                                                  </div>
                                                              </div>
                                                          );
                                                      }
                                                  })
                                            : null}

                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <video width="320" height="240" controls controlsList="nodownload">
                                                <source
                                                    src="https://nft-store-assets.s3.ap-south-1.amazonaws.com/videos/NFT's+Explained+in+4+minutes!+(online-video-cutter.com).mp4"
                                                    type="video/mp4"
                                                />
                                            </video>
                                        </div>

                                        {nftDetails.nft_id ? (
                                            <Grid container justifyContent="center">
                                                <Grid item>
                                                    <CustomTextButton
                                                        color="primary"
                                                        title="View On Solana"
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ color: "black" }}
                                                        disabled={!nftDetails?.nft_id}
                                                        onClick={() => window.open(getSolExplorerUrl(nftDetails.nft_id))}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : null}

                                        {nftDetails.is_for_sale ? (
                                            <Grid container justifyContent="center">
                                                <Grid item>
                                                    <CustomTextButton
                                                        color="primary"
                                                        title={`Buy now (${nftDetails?.selling_price} INR)`}
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ color: "black" }}
                                                        disabled={!nftDetails.nft_id}
                                                        onClick={() =>
                                                            window.open(
                                                                `https://thetokenlist.com/auth/buy-artwork/${nftDetails?.nft_id}`,
                                                                "_self"
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : null}
                                    </div>
                                </Card>
                            </>
                        ) : (
                            <div style={{ display: "flex", justifyContent: "center", padding: "1.5em" }}>
                                <h4>This Contract does not have any details</h4>
                            </div>
                        )}
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default PublicNftDetails;
