/*eslint-disable*/
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Grid } from "@material-ui/core";
import { Home } from "@material-ui/icons";
// @material-ui/core components
import { CircularProgress as Loader } from "@material-ui/core";
import "./../styles.css";

import { history, isLoggedIn } from "utils";

function SendOtp({ onSubmit }) {
    const { isAuthenticated, isLoading } = useSelector(({ auth: { token }, loading }) => ({
        isAuthenticated: isLoggedIn(token),
        isLoading: loading,
    }));

    useEffect(() => {
        if (isAuthenticated) {
            history.push("/app/nfts");
        }
    }, []);

    return (
        <>
            <Button href="https://thetokenlist.com/" variant="outlined" className="back-button">
                <Home />
            </Button>
            <div className="outer">
                <div className="login-box">
                    <h2>Sign In</h2>
                    <Formik
                        initialValues={{
                            email: "",
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
                        })}
                        onSubmit={onSubmit}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="user-box">
                                    <input
                                        autoComplete="on"
                                        type="email"
                                        name="email"
                                        defaultValue={values.email}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required={true}
                                    />
                                    {Boolean(touched.email && errors.email) ? (
                                        <div
                                            style={{
                                                display: "block",
                                                marginLeft: "10px",
                                                color: "red",
                                                fontSize: 13,
                                            }}
                                        >
                                            {errors.email}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <label>Email</label>
                                </div>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <a href="#" onClick={handleSubmit}>
                                            <span />
                                            <span />
                                            <span />
                                            <span />
                                            {isLoading ? <Loader color="inherit" size={20} /> : "SEND OTP"}
                                        </a>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                    <br />
                    <Grid container justifyContent="center">
                        <Grid item>
                            <p style={{ color: "grey" }}>
                                Not registered yet?{" "}
                                <a onClick={() => history.push("/auth/signup")} style={{ color: "white" }}>
                                    sign up here
                                </a>
                            </p>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default SendOtp;
