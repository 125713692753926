/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card } from "reactstrap";
import { FormControl, Grid, MenuItem, Select, Switch } from "@material-ui/core";

import CustomTable from "components/Custom/Tables/CustomTable";
import CustomIconButton from "components/Custom/Buttons/IconButton";
import CustomModal from "components/Custom/Modal";
import CreateNewNft from "./create-nfts";

import { listAllNfts, resetNftsData, showFunqCredits, resetFunqCredits, toggleViewMode } from "store/actions";
import CustomTextButton from "components/Custom/Buttons/TextButton";
import { history } from "utils";
import { toast } from "react-toastify";
import NftCard from "components/Cards/NftCard";

const NftList = () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState("");
    const [addNewModal, setAddNewModal] = useState(false);
    const [infoModal, setInfoModal] = useState(false);

    const { nftsData, showViewMore, funqCreditsData } = useSelector(
        ({ nfts: { list, showViewMore }, users: { funqCredits } }) => ({
            nftsData: list,
            showViewMore,
            funqCreditsData: funqCredits,
        })
    );
    const { viewMode } = useSelector(({ txns: { isCardView } }) => ({
        viewMode: isCardView,
    }));

    useEffect(() => {
        dispatch(resetNftsData());
        fetchMoreNfts();
        dispatch(showFunqCredits());
        dispatch(resetFunqCredits());
    }, [status]);

    const fetchMoreNfts = () => {
        dispatch(listAllNfts({ status: status }));
    };

    const handleAddNewModalClose = () => {
        setAddNewModal(false);
    };

    const handleInfoModalClose = () => {
        setInfoModal(!infoModal);
    };

    const handleAddnewButton = () => {
        if (funqCreditsData?.credits === 0) {
            toast.error("You have no credits");
        } else {
            setAddNewModal(true);
        }
    };

    const handleBulkUploadButton = () => {
        if (funqCreditsData?.credits === 0) {
            toast.error("You have no funq credits");
        } else {
            history.push("/app/upload");
        }
    };

    const handleChange = (event) => {
        dispatch(toggleViewMode(event.target.checked));
    };

    return (
        <>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <div style={{ padding: "1em" }}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <CustomIconButton
                                            id="add-new-btn"
                                            title="Add New"
                                            icon="fas fa-plus"
                                            onClick={handleAddnewButton}
                                        />
                                    </Grid>
                                    {/* <Grid item>
                                                <CustomIconButton
                                                    id="upload-btn"
                                                    title="Bulk Upload"
                                                    icon="fas fa-upload"
                                                    onClick={handleBulkUploadButton}
                                                />
                                            </Grid> */}
                                    <Grid item>
                                        <CustomTextButton
                                            title="Sell NFTs"
                                            onClick={() => history.push("/app/sell-nfts")}
                                        />
                                    </Grid>
                                    <Grid
                                        xl={6}
                                        lg={4}
                                        md={3}
                                        sm={6}
                                        xs={8}
                                        item
                                        style={{ padding: "15px", fontSize: "18px" }}
                                    >
                                        Card view
                                        <Switch
                                            checked={viewMode}
                                            onChange={handleChange}
                                            inputProps={{ "aria-label": "controlled" }}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={4} md={4} sm={6} xs={4}>
                                        <Grid container spacing={1} justifyContent="flex-end" style={{ padding: "15px" }}>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                style={{ maxWidth: "200px", minWidth: "120px" }}
                                            >
                                                <Select
                                                    value={status}
                                                    onChange={(event) => {
                                                        setStatus(event.target.value);
                                                    }}
                                                    displayEmpty
                                                >
                                                    <MenuItem value="">
                                                        <em>All</em>
                                                    </MenuItem>
                                                    <MenuItem value="pending">Pending</MenuItem>
                                                    <MenuItem value="created">Created</MenuItem>
                                                    <MenuItem value="sold">Sold</MenuItem>
                                                    <MenuItem value="sales">Listed</MenuItem>
                                                    <MenuItem value="transferred">Transferred</MenuItem>
                                                    <MenuItem value="received">Received</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {!viewMode ? (
                            <CustomTable
                                title="NFT collections"
                                columns={[
                                    {
                                        title: "Actions",
                                        render: (rowData) => (
                                            <CustomTextButton
                                                title="open"
                                                otherProps={{ size: "sm" }}
                                                onClick={() => history.push(`/app/nft/${rowData._id}`)}
                                            />
                                        ),
                                    },
                                    { title: "Name", field: "name" },
                                    { title: "Description", field: "description" },
                                    { title: "NFT ID", field: "nft_id" },
                                    {
                                        title: "Artwork",
                                        field: "art_url",
                                        render: (rowData) => <img src={rowData?.art_url} width={40} height={40} />,
                                    },
                                    { title: "Status", field: "status" },
                                ]}
                                data={nftsData}
                                showViewMoreButton={showViewMore}
                                viewMoreButtonClick={() => fetchMoreNfts()}
                            />
                        ) : (
                            <NftCard
                                data={nftsData}
                                showViewMoreButton={showViewMore}
                                viewMoreButtonClick={() => fetchMoreNfts()}
                            />
                        )}
                    </Grid>
                </Grid>
                <CustomModal
                    content={<CreateNewNft handleModal={handleAddNewModalClose} handleInfoModal={handleInfoModalClose} />}
                    visible={addNewModal}
                    title="Contract details"
                    handleClose={handleAddNewModalClose}
                />
                <CustomModal
                    content={
                        <>
                            The artwork will be converted to NFT within 24 hours. You will get an email notification when
                            it's converted
                        </>
                    }
                    visible={infoModal}
                    title="NFT uploaded"
                    handleClose={handleInfoModalClose}
                />
            </Container>
        </>
    );
};

export default NftList;
